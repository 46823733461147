<template>
  <v-navigation-drawer
    :color="strBackgroundColor"
    fixed
    height="100%"
    :width="$vuetify.breakpoint.smAndUp ? 256 : '100%'"
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
    right
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-container>
      <v-row align="center">
        <v-col>
          <v-img
            :src="require('@/assets/logos/logo-kedatech-white.png')"
            contain
            max-width="100"
            class="my-auto"
            max-height="45"
          />
        </v-col>

        <v-spacer />

        <v-col class="col-auto">
          <v-btn icon color="white" @click="handleClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-window v-model="iNavTypeIndex">
      <v-window-item eager>
        <v-list nav flat>
          <v-list-item
            v-for="(item, i) in arrPages"
            :key="'navdrawer-item-menu-' + i"
            :to="item.isMenu ? '' : { name: item.strPathName }"
            :class="` text-center white--text ${item.isMenu && 'px-0'} ${
              !item.isMenu && item.strPathName === $route.name && 'black'
            }`"
          >
            <v-list-item-content v-if="!item.isMenu" @click="handleClick">
              <v-list-item-title class="kda-ts-18pt nunito wt-extrabold">
                {{ item.strText }}
              </v-list-item-title>
            </v-list-item-content>

            <v-expansion-panels v-else v-model="panel" accordion flat tile>
              <v-expansion-panel v-model="panel">
                <v-expansion-panel-header
                  class="kda-ts-18pt nunito wt-extrabold d-flex justify-center"
                  style="position: relative"
                  hide-actions
                  @click="isMenuExpanded = !isMenuExpanded"
                >
                  {{ item.strText }}

                  <v-icon color="white" style="position: absolute; right: 12px">
                    {{ isMenuExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                  </v-icon>
                </v-expansion-panel-header>

                <v-expansion-panel-content class="pa-0">
                  <v-list-item
                    v-for="(child, iChild) in item.menus"
                    :key="'navdrawer-subitem-menu-' + iChild"
                    :to="{ name: child.strPathName }"
                    :class="`text-center white--text ${
                      child.strPathName === $route.name && 'black'
                    }`"
                    @click="handleClick"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="kda-ts-18pt nunito wt-extrabold">
                        {{ child.strText }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-list-item>
        </v-list>

        <v-btn block text>
          <v-img :src="require('@/assets/logos/logo-uptech-white.png')" contain max-height="24" />
        </v-btn>

        <v-btn
          text
          color="white"
          class="kda-ts-18pt nunito wt-extrabold text-capitalize my-2"
          block
          @click="$emit('btn')"
        >
          {{ strBtn }}
        </v-btn>

        <v-btn
          class="kda-ts-18pt nunito wt-extrabold d-flex justify-center text-capitalize my-2 mb-8"
          style="position: relative"
          text
          color="white"
          block
          @click="iNavTypeIndex++"
        >
          <k-d-a-lang-item
            :str-title="m_objCurrentLang.strName"
            :lang-src="m_objCurrentLang.logoSrc"
          />

          <v-icon color="white" style="position: absolute; right: 12px"> mdi-chevron-right </v-icon>
        </v-btn>
      </v-window-item>

      <v-window-item eager>
        <v-btn
          class="kda-ts-18pt nunito wt-extrabold d-flex justify-center text-capitalize my-2"
          style="position: relative"
          text
          color="white"
          block
          @click="iNavTypeIndex--"
        >
          {{ m_strLangTitle }}

          <v-icon color="white" style="position: absolute; left: 12px"> mdi-chevron-left </v-icon>
        </v-btn>

        <v-list nav flat>
          <v-list-item
            v-for="(lang, index) in arrLangs"
            :key="'changing-lang-nav-item-' + index"
            class="text-center white--text"
            @click="changeLang(lang.strCode)"
          >
            <v-list-item-content>
              <v-list-item-title class="kda-ts-18pt nunito wt-extrabold">
                <k-d-a-lang-item :str-title="lang.strName" :lang-src="lang.logoSrc" />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-window-item>
    </v-window>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'KDANavigationDrawer',

  components: {
    KDALangItem: () => import('@/components/KDALangItem')
  },

  props: {
    arrPages: {
      type: Array,
      default: () => []
    },
    arrLangs: {
      type: Array,
      default: () => []
    },
    strBackgroundColor: {
      type: String,
      default: 'main-bw-500'
    },
    strColor: {
      type: String,
      default: 'white'
    },
    strBtn: {
      type: String,
      default: 'Button'
    }
  },

  data() {
    return {
      isMenuExpanded: false,
      iNavTypeIndex: 0,
      panel: undefined
    };
  },

  computed: {
    m_objCurrentLang() {
      return this.arrLangs?.filter(
        function (lang) {
          return lang.strCode === this.$vuetify.lang.current;
        }.bind(this)
      )?.[0];
    },
    m_strLangTitle() {
      return 'Bahasa';
    }
  },

  methods: {
    handleClick() {
      this.panel = undefined;
      this.isMenuExpanded = false;
    },
    handleClose() {
      this.handleClick();
      this.iNavTypeIndex = 0;
      this.$emit('close');
    },
    changeLang(code) {
      this.$emit('lang', code);
      this.handleClose();
    }
  }
};
</script>

<style scoped lang="scss">
.v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;
  color: white !important;
}

.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
  background-color: #121212 !important;
}
</style>
